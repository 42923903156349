<template>
  <div class="box col-wrapper">
    <!--  选择条件  -->
    <div class="col-wrapper">
      <div class="form-row">
        <el-form class="form-left" inline label-width="80px">
          <el-form-item label="地区">
            <el-cascader
                v-model="form.area"
                :options="options"
                :props="{expandTrigger: 'hover',checkStrictly: true}"
                clearable>
              <template #default="{node,data}">
                <div @click="selectArea(node)">
                  <span>{{ data.label }}</span>
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="类别">
            <el-cascader
                v-model="form.category"
                :options="categoryList"
                :props="categoryProps"
                clearable/>
          </el-form-item>
          <el-form-item label="基地">
            <el-select
                v-model="form.base"
                :remote-method="baseSelect"
                class="base"
                clearable
                filterable
                remote>
              <el-option
                  v-for="item in baseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-row">
        <el-form inline label-width="80px">
          <el-form-item class="titleItem" label="标题">
            <el-input v-model="form.title" clearable/>
          </el-form-item>
          <el-form-item label="发布时间">
            <el-date-picker
                v-model="form.dateRange"
                :shortcuts="dateRangeConfig"
                end-placeholder="截止日期"
                range-separator="到"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                value-format="YYYY-MM-DD"/>
          </el-form-item>
          <el-form-item>
            <Button type="primary" @click="GetArticleList">搜索</Button>
            <Button @click="resetSelect">重置</Button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  表格  -->
    <div style="align-self: stretch">
      <el-table
          :data="tableData"
          max-height="70vh">
        <el-table-column
            :show-overflow-tooltip="true"
            align="left"
            fixed="left"
            label="标题"
            min-width="180"
            prop="title">
        </el-table-column>
        <el-table-column
            align="center"
            label="地区"
            min-width="100">
          <template #default="scope">
            {{ isNull(scope.row.province) + " " + isNull(scope.row.city) + " " + isNull(scope.row.district) }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="基地"
            min-width="100"
            prop="baseName">
        </el-table-column>
        <el-table-column
            align="center"
            label="类别"
            min-width="100"
            prop="categoryName">
        </el-table-column>
        <el-table-column
            align="center"
            label="发布人"
            min-width="100"
            prop="add_username">
        </el-table-column>
        <el-table-column
            align="center"
            label="发布时间"
            min-width="100"
            prop="last_update_time">
        </el-table-column>
        <el-table-column
            align="center"
            label="发布状态"
            min-width="100">
          <template
              #default="scope">
            <el-tag
                v-if="scope.row.status==='01'"
                type="success">显示
            </el-tag>
            <el-tag
                v-else
                type="danger">隐藏
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            min-width="260">
          <template #default="scope">
            <Button
                size="small"
                type="primary"
                @click="editHistroy(scope.row)">修改
            </Button>
            <Button
                size="small"
                @click="checkArtical(scope.row.id)">查看
            </Button>
            <el-popconfirm
                cancel-button-text="取消"
                confirm-button-text="确认"
                :title="'确认删除 '+scope.row.title+' 吗？'"
                @confirm="delArticle(scope.row.id)"
            >
              <template #reference>
                <Button
                    :type="'danger'"
                    size="small">删除
                </Button>
              </template>
            </el-popconfirm>
            <Button
                :type="scope.row.status==='02'?'success':'danger'"
                size="small"
                @click="hiddenOrShowArtical(scope.row)">
              {{ scope.row.status === '02' ? '显示' : '隐藏' }}
            </Button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        :page-sizes="[10, 20, 30, 40]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 5px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"/>
  </div>
</template>
<script>
import * as ResourceApi from "../../api/api/Resources"

export default {
  name: "Index",
  data() {
    return {
      form: {
        area: [],
        base: undefined,
        category: 0,
        title: "",
        dateRange: ["", ""],
        content: ""
      },
      baseList: [],
      areaData: require("../../assets/json/ssq.json"),
      categoryList: [],
      categoryProps: {
        value: "id",
        label: "name",
        children: "childItem",
        emitPath: false
      },
      // --- 表格 ---
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 100
      },
    }
  },
  computed: {
    /**
     * 三级联动选项的数据
     * @return {{}}
     */
    options() {
      return this.areaData.province
    },
    tableStyle() {
      let width = window.screen.width
      console.log(width)
      switch (width > 1550) {
        case true:
          return {
            'width': '100%'
          }
        case false:
          return {
            'width': '980px'
          }
        default:
          return {}
      }
    },
    /**
     * 快速选择配置
     */
    dateRangeConfig() {
      return [
        {
          text: "最近一周",
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '最近一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '最近三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ]
    },
  },
  activated() {
    this.GetArticleList()
    this.GetCategoryList()
    this.GetBaseList()
  },
  methods: {
    // --- 事件 ---
    /**
     * 基地输入字符
     * @param val
     */
    baseSelect(val) {
      this.form.base = val
    },
    /**
     * 重置筛选表单
     */
    resetSelect() {
      this.form = {
        area: [],
        base: undefined,
        category: 0,
        title: "",
        dateRange: ["", ""],
        content: ""
      }
    },
    /**
     * 选择区域
     * @param val
     */
    selectArea(val) {
      this.form.area = val.pathValues
      this.form.areaNames = val.pathLabels
      this.GetBaseList()
    },
    /**
     * 修改 文章
     * @param val
     */
    editHistroy(val) {
      // const page = this.$router.resolve({path: "/editArtical", query: {id: val.id}})
      // window.open(page.href)
      this.$router.push({path: "/editArtical", query: {id: val.id}})
    },
    /**
     * 展示文章细节
     */
    checkArtical(id) {
      const page = this.$router.resolve({path: "/newsDetail", query: {id}})
      window.open(page.href, '', 'width=400,height=850,top=0,left=800,location=no,menubar=no,status=no,toolbar=no')
    },
    /**
     * 表格页大小
     * @param val
     */
    handleSizeChange(val) {
      this.page.page = 1
      this.page.limit = val
      this.GetArticleList()
    },
    /**
     * 翻页
     * @param val
     */
    handleCurrentChange(val) {
      this.page.page = val
      this.GetArticleList()
    },
    /**
     * 显示或隐藏某文章
     * @param val
     */
    hiddenOrShowArtical(val) {
      const nowStatus = val.status === "01" ? "02" : "01"
      this.UpdateStatusForArticle(val.id, nowStatus)
    },
    //   --- axios ---
    /**
     * 获取基地列表
     * @constructor
     */
    GetBaseList() {
      if (this.form.area === null) {
        this.form.area = []
      }
      const pd = {
        type: this.form.area.length === 0 ? 3 : this.form.area.length - 1, // 0 - 省 1 - 市 2 - 区
        regionCode: this.form.area.length === 0 ? "" : this.form.area[this.form.area.length - 1]
      }
      ResourceApi.GetBaseList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.baseList = res.data.data
              if (res.data.data.length > 0) {
                this.form.base = ""
              } else {
                this.$message.error("无基地数据")
                this.form.base = undefined
              }
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
    },
    /**
     * 删除文章
     * @param id 文章id
     */
    delArticle(id) {
      const pd = {id}
      ResourceApi.DeleteArticle(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetArticleList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
    },
    /**
     * 获取发布历史
     * @constructor
     */
    GetArticleList() {
      if (this.form.dateRange === null) {
        this.form.dateRange = ["", ""]
      }
      if (this.form.area === null) {
        this.form.area = []
      }
      const regionCode = this.form.area.length === 0 ? "" : this.form.area[this.form.area.length - 1]
      const pd = {
        regionType: this.form.area.length - 1 === -1 ? 0 : this.form.area.length - 1,
        regionCode,
        baseName: this.form.base === undefined ? '' : this.form.base,
        categoryId: this.form.category === null ? 0 : this.form.category,
        title: this.form.title,
        startTime: this.form.dateRange[0],
        endTime: this.form.dateRange[1],
        content: this.form.content,
        currentPage: this.page.page,
        pagesize: this.page.limit,
      }
      ResourceApi.GetArticleList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.tableData = res.data.data.datalist
              this.page.total = res.data.data.totalSize
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
          })
    },
    /**
     * 获取分类
     * @constructor
     */
    GetCategoryList() {
      const pd = {}
      ResourceApi.GetCategoryList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.categoryList = res.data.data
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
    },
    /**
     * 文章禁用或启用
     * @param id 文章id
     * @param status 状态（01启用02禁用）
     * @constructor
     */
    UpdateStatusForArticle(id, status) {
      const pd = {id, status}
      ResourceApi.UpdateStatusForArticle(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetArticleList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("更新失败")
          })
    },
    //   --- 数据处理 ---
    /**
     * 字符串中的Null转换为空字符串
     * @param val
     * @return {string|*}
     */
    isNull(val) {
      return val === null ? "" : val
    }
  },
}
</script>
<style>
.el-radio {
  display: none;
}
</style>
<style lang="less" scoped>
.box {
  .form-row {
    width: 950px;

    .titleItem {
      ::v-deep(.el-input__wrapper) {
        width: 192px;
      }
    }
  }


  ::v-deep(.el-form--inline .el-form-item) {
    margin-right: 16px;
  }
}
</style>
